import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@repo/ui/components/ui/avatar";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Flame } from "lucide-react";
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import invariant from 'tiny-invariant';
import { InfoIcon, } from "../../organisms/";
import { components } from '../../services/api/openapi';

const timeAgo = (timestamp: string): string => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = Math.floor(hours / 24);
  if (days < 30) {
    return `${days}d`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months}mo`;
  }

  const years = Math.max(1, Math.floor(days / 365));
  return `${years}y`;
}

const isRecent = (timestamp: string): boolean => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);
  const hours = Math.floor(seconds / 3660);
  return hours <= 48;
}

interface ScoreBreakdown {
  score: number;
  reason: string;
}

interface ScoreBreakdownAccordionProps {
  criteria: string;
  tooltip: string;
  score: number;
  reason: string;
}

const ScoreBreakdownAccordion: React.FC<ScoreBreakdownAccordionProps> = ({
  criteria,
  tooltip,
  score,
  reason
}) => {
  return (
    <AccordionItem value={criteria}>
      <AccordionTrigger className="py-2">
        <div className="flex gap-2 items-center">
          <div className="flex gap-1 items-center">
            <span className="text-sm">{criteria}</span>
            <InfoIcon className="size-4" text={tooltip} />
          </div>
          <span className="text-xs">{score}/100</span>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <p className="text-muted-foreground">{reason}</p>
      </AccordionContent>
    </AccordionItem>
  )
}

ScoreBreakdownAccordion.displayName = "ScoreBreakdownAccordion"

interface RedditSubmissionScoreBreakdownProps {
  overallScore: number;
  scoreBreakdown: {
    relevancy: ScoreBreakdown,
    appeal: ScoreBreakdown,
    compliance: ScoreBreakdown,
    reach: ScoreBreakdown,
  }
}

const RedditSubmissionScoreBreakdown: React.FC<RedditSubmissionScoreBreakdownProps> = ({
  overallScore,
  scoreBreakdown
}) => {
  return (
    <Card>
      <CardHeader className="p-4 pb-2">
        <h3 className="text-md font-semibold">
          <div className="flex gap-1 items-center">
            Score Breakdown
            <InfoIcon
              className="size-4"
              text="The score is a weighted average of relevancy (40%), appeal (30%), reach (20%), and compliance (10%). If relevancy is below a certain threshold, the score decreases linearly based on the relevancy value."
            />
          </div>
        </h3>
        <CardDescription>Overall score: {overallScore}</CardDescription>
      </CardHeader>
      <CardContent className="p-4 pt-0">
        <div className="w-full">
          <Accordion type="single" collapsible className="w-full">
            <ScoreBreakdownAccordion
              criteria="Relevancy"
              tooltip="This measures how well the content of the submission matches the value proposition of your product."
              score={scoreBreakdown.relevancy.score}
              reason={scoreBreakdown.relevancy.reason}
            />
            <ScoreBreakdownAccordion
              criteria="Appeal"
              tooltip="This measures how likely your response is to spark interest, comments, or upvotes based on the submission’s appeal to the audience."
              score={scoreBreakdown.appeal.score}
              reason={scoreBreakdown.appeal.reason}
            />
            <ScoreBreakdownAccordion
              criteria="Compliance"
              tooltip="Reflects how subreddit rules allow or restrict engagement, particularly in terms of self-promotion or recommending a product."
              score={scoreBreakdown.compliance.score}
              reason={scoreBreakdown.compliance.reason}
            />
            <ScoreBreakdownAccordion
              criteria="Reach"
              tooltip="Reflects the potential number of people your response might reach given the size of the subreddit."
              score={scoreBreakdown.reach.score}
              reason={scoreBreakdown.reach.reason}
            />
          </Accordion>
        </div>
      </CardContent>
    </Card >
  )
}

RedditSubmissionScoreBreakdown.displayName = "RedditSubmissionScoreBreakdown";

interface RedditSubmissionCandidateCardProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
}

const RedditSubmissionCandidateCard: React.FC<RedditSubmissionCandidateCardProps> = ({
  submissionCandidate
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing Reddit submission")
  invariant(submissionCandidate.submission.discovery, "Unexpected missing Reddit submission.discovery")
  invariant(submissionCandidate.submission.stats, "Unexpected missing Reddit submission.stats")

  return (
    <div className="flex flex-col gap-2 w-full">
      <Card className="border-none">
        <CardHeader className="w-full">
          <RedditSubmissionCardHeader submissionCandidate={submissionCandidate} />
        </CardHeader>
        <CardContent className="w-full flex flex-col gap-2">

          <Card>
            <CardHeader className="p-4 pb-2">
              <h3 className="text-md font-semibold">Summary</h3>
            </CardHeader>
            <CardContent className="p-4 pt-0">
              <ReactMarkdown
                className="max-w-none prose-sm prose italic text-muted-foreground"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeHighlight]}
              >
                {submissionCandidate.summary}
              </ReactMarkdown>
            </CardContent>
          </Card>

          {submissionCandidate.score_breakdown && (
            <RedditSubmissionScoreBreakdown
              overallScore={submissionCandidate.score}
              scoreBreakdown={submissionCandidate.score_breakdown}
            />
          )}

          <Card>
            <CardHeader className="p-4 pb-2">
              <h3 className="text-md font-semibold">Original Content</h3>
            </CardHeader>
            <CardContent className="p-4 pt-0">
              <ReactMarkdown
                className="max-w-none prose-sm prose italic text-muted-foreground"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeHighlight]}
              >
                {submissionCandidate.submission.text}
              </ReactMarkdown>
            </CardContent>
          </Card>

        </CardContent>
      </Card>
    </div>
  )
}

interface RedditSubmissionCardHeaderProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
}

const RedditSubmissionCardHeader: React.FC<RedditSubmissionCardHeaderProps> = ({
  submissionCandidate
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing Reddit submission")
  invariant(submissionCandidate.submission.discovery, "Unexpected missing Reddit submission.discovery")
  invariant(submissionCandidate.submission.stats, "Unexpected missing Reddit submission.stats")

  return (
    <>
      <div className="flex flex-col gap-4 justify-between items-center">
        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Avatar>
                <AvatarImage src={submissionCandidate.submission.sub_avatar_link} alt={submissionCandidate.submission.sub} />
                <AvatarFallback>{submissionCandidate.submission.sub[0]}</AvatarFallback>
              </Avatar>
              <div className="flex flex-col">
                <span className="text-xs font-semibold">r/{submissionCandidate.submission.sub}</span>
                <div className="flex gap-2 h-5 text-xs items-center">
                  <span className="text-xs">u/{submissionCandidate.submission.author}</span>
                  ·
                  <span className="flex gap-2 items-center">
                    <span className="text-xs">{timeAgo(submissionCandidate.submission.submitted_at)}</span>
                  </span>
                  {
                    isRecent(submissionCandidate.submission.submitted_at) && (
                      <div
                        className="flex h-5 justify-center items-center bg-red-600/30 rounded-md"
                      >
                        <div className="w-5 h-5 flex justify-center items-center animate-ping">
                          <Flame strokeWidth={3} className="size-3 text-red-600 fill-orange-300" />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <CardTitle className="text-md">{submissionCandidate.submission.title}</CardTitle>
        </div>

      </div>
    </>
  )
}

RedditSubmissionCardHeader.displayName = "RedditSubmissionCardHeader";

export { RedditSubmissionCandidateCard };
