import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { useQuery } from '@tanstack/react-query';
import React from "react";
import { Plan, PricingTable, Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';


const SubscriptionCard = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Subscription</CardTitle>
      </CardHeader>
      <CardContent>
        <SubscriptionCardContent />
      </CardContent>
    </Card>
  )
}

const SubscriptionCardContent = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const { data, error, status } = useQuery({
    queryKey: ["get", "subscriptions", "reference"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/subscriptions/reference", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: ["plan"]
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  const subscription = data.data
  const plan = data.data.plan;
  const periodEnd = subscription.current_end_date ?
    new Date(subscription.current_end_date) : null;
  const subscriptionStatus = subscription.status as ("active" | "past_due" | "canceled");

  return (
    <Plan
      name={plan?.name || ""}
      amountCents={plan?.amount_cents || 0}
      currency={plan?.currency || ""}
      interval={plan?.interval || null}
      periodEnd={periodEnd}
      status={subscriptionStatus}
    />
  )
}

const PlansCard = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Plans</CardTitle>
      </CardHeader>
      <CardContent>
        <PlansCardContent />
      </CardContent>
    </Card>
  )
}

const PlansCardContent = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const { data, error, status } = useQuery({
    queryKey: ["get", "billing", "pricing-table"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/billing/pricing-table", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            return_url: `${window.location.origin}/user-management/profile/subscription/`
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <PricingTable pricingDefinitions={data.data.definition} />
  )
}


const Subscription = () => {
  return (
    <div className="flex flex-col gap-4">
      <SubscriptionCard />
      <PlansCard />
    </div>
  )
}

export { Subscription };
