import { useAuth } from '@clerk/clerk-react';
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@repo/ui/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";
import { Input } from "@repo/ui/components/ui/input";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Sparkles } from 'lucide-react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from "react-hook-form";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';

const formSchema = z.object({
  website: z.string().url(),
})

type FormType = z.infer<typeof formSchema>;

const ProjectsCreate = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  })

  const projectsCreation = useMutation({
    mutationFn: async (projectPayload: components["schemas"]["CreateProjectRequest"]) => {
      const { data, error, response } = await fetcher.POST("/projects", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: projectPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['get', 'projects'] });
      toast.success(data.message)
      return navigate(`/projects/edit/${data.data.id}`);
    },
    onError: (error) => {
      toast.error("Cannot create project",
        { description: `${error}` })
    },
  })

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      {projectsCreation.status === "pending" ? (
        <span className="text-xl animate-pulse">Analyzing your website...</span>
      ) : (
        <Card className="w-96">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(({ website }) => {
                projectsCreation.mutate({
                  website
                })
              })}
              className="flex flex-col gap-6"
            >
              <CardHeader className="text-center">
                <CardTitle>Start Your Journey</CardTitle>
                <CardDescription>Enter your website, and we'll fill the rest.</CardDescription>
              </CardHeader>
              <CardContent className="text-center">
                <FormField
                  control={form.control}
                  name="website"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Website</FormLabel>
                      <FormControl>
                        <Input placeholder="https://myawesomewebsite.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
              <CardFooter className="flex items-center justify-center">
                <Button
                  type="submit"
                  variant="accent"
                  className="flex gap-2"
                >
                  <Sparkles className="size-5  animate-pulse" />
                  <span className="animate-pulse">Make It Happen</span>
                </Button>
              </CardFooter>
            </form>
          </Form>
        </Card>
      )}
    </div>
  )
}

export { ProjectsCreate };
