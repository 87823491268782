import { UserButton } from "@clerk/clerk-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@repo/ui/components/ui/breadcrumb";
import React from "react";
import { Link, useMatches } from "react-router-dom";


interface TopBarProps {}

const TopBar: React.FC<TopBarProps> = () => {
  const matches = useMatches();
  const cleanedMatches = (
    matches.length > 1 &&
    matches[matches.length - 1].pathname === matches[matches.length - 2].pathname
  ) ? matches.splice(0, matches.length - 1) : matches;
  const match = cleanedMatches.length > 1 ? cleanedMatches[1] : null;
  const pathLevels = match ?
    ["Home", ...match.pathname
      .replace(/\/$/, '')
      .split('/')
      .filter(Boolean)
      .map((_, i, a) => '/' + a.slice(0, i + 1).join('/'))] : [];

  return (
    <header className="flex items-center justify-between h-10">
      <Breadcrumb>
        {
          pathLevels && (
            <BreadcrumbList>
              {
                pathLevels.map((path, i) => {
                  const rawLabel = path.split("/")[i] as string;
                  const label = rawLabel
                    .replaceAll("-", " ")
                    .toLowerCase()
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ') || "Home";

                  return (
                    <React.Fragment key={`${label}-${i}`}>
                      {i > 0 && <BreadcrumbSeparator />}
                      <BreadcrumbItem >
                        {
                          i < pathLevels.length - 1 ?
                            <BreadcrumbLink asChild>
                              <span className="hover:text-muted-foreground">
                                {label}
                              </span>
                            </BreadcrumbLink> :
                            <BreadcrumbPage>{label}</BreadcrumbPage>
                        }
                      </BreadcrumbItem>
                    </React.Fragment>
                  )
                })
              }
            </BreadcrumbList>
          )
        }
      </Breadcrumb>

      <UserButton
        userProfileMode="navigation"
        userProfileUrl="/user-management/profile/account"
        appearance={{
          elements: {
            rootBox: "w-10 h-10",
            userButtonTrigger: "w-full h-full",
            userButtonBox: "w-full h-full",
            userButtonAvatarBox: "w-full h-full"
          }
        }}
      />
    </header>
  )
}

TopBar.displayName = "TopBar";

export { TopBar };
