import { useAuth, useUser } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button.tsx";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { useQuery } from '@tanstack/react-query';
import { useEffect } from "react";
import { useShallow } from 'zustand/react/shallow';
import { Spinner } from "../organisms";
import { fetcher, serializeError } from '../services/api';
import { usePrefsStore } from '../store';


interface IntegrationsSetupNewProps {
}


const IntegrationsSetupNew: React.FC<IntegrationsSetupNewProps> = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const [setRedditOAuthState] = usePrefsStore(
    useShallow((state) => [state.setRedditOAuthState]),
  )

  const {
    data: integrationsAuthUrlData,
    error: integrationsAuthUrlError,
    status: integrationsAuthUrlStatus
  } = useQuery({
    queryKey: ["get", "integrations", "auth-url"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/integrations/auth-url", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn,
    gcTime: 0,
  })

  useEffect(() => {
    if (integrationsAuthUrlStatus === "success") {
      setRedditOAuthState(integrationsAuthUrlData.data.oauth_state)
    }
  }, [integrationsAuthUrlStatus, integrationsAuthUrlData])

  if (integrationsAuthUrlStatus === "pending") {
    return (
      <Spinner />
    )
  }

  if (integrationsAuthUrlStatus === "error") {
    return <span>Error: {integrationsAuthUrlError.message}</span>
  }

  return (
    <div className="flex flex-col gap-8 h-full">
      <h1 className="text-3xl font-semibold">Integrations Setup</h1>

      <div className="flex flex-col gap-4 w-full h-full items-center justify-center">
        <Card
          className="max-w-2xl text-center"
        >
          <CardHeader>
            <CardTitle>Grant authorization</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4 justify-center items-center">
              <span>Click on the following button to initiate the authorization process.</span>
              <Button variant="accent" asChild className="w-fit">
                <a href={integrationsAuthUrlData.data.auth_url} target="_blank">Start</a>
              </Button>
            </div>
          </CardContent>
        </Card>

      </div>
    </div>
  )
}


IntegrationsSetupNew.displayName = "IntegrationsSetupNew";


export { IntegrationsSetupNew };
