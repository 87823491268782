import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { PartyPopper } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const Verify = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-center flex items-center gap-2 justify-center">
          Your account has been verified! <PartyPopper strokeWidth={2} className="size-6" />
        </CardTitle>
      </CardHeader>
      <CardContent className="text-center">
        <Button
          size="sm"
          aria-label="Go to login"
          asChild
        >
          <Link to="/auth/sign-in">
            <div className="flex items-center gap-2">
              <span>Go to login</span>
            </div>
          </Link>
        </Button>
      </CardContent>
    </Card>
  )
}

export { Verify };
