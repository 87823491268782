import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Inbox } from "lucide-react";

import React from "react";

const VerifyEmailAddress = () => {
  return (
    <Card className="max-w-2xl">
      <CardHeader>
        <CardTitle className="text-center flex items-center gap-2 justify-center">
          Check your inbox <Inbox strokeWidth={2} className="size-6" />
        </CardTitle>
      </CardHeader>
      <CardContent className="text-center">
        <p>
          We've sent you an email with a verification link.
        </p>
        <p>
          Please check your inbox (including spam or junk folder) and click the link to confirm your email address.
        </p>
      </CardContent>
    </Card>
  )
}

export { VerifyEmailAddress };
