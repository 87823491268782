class Plausible {
  track(
    eventName: string,
    metadata: { [key: string]: string | number | boolean }
  ): void {
    window.plausible && window.plausible(eventName, { props: metadata });
  }
}

const plausible = new Plausible();

export { plausible };
