import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  let error = useRouteError();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <Card className="min-w-80 w-fit">
        <CardHeader>
          <CardTitle className="text-center">Oops!</CardTitle>
          <CardDescription>
            An unexpected application error occurred.
          </CardDescription>

        </CardHeader>

        <CardContent>
          <p>We'll take care of this!</p>
          <p>In the meantime, try refreshing the page.</p>
        </CardContent>
      </Card>
    </div>
  )
}

ErrorBoundary.displayName = "ErrorBoundary";

export { ErrorBoundary };
