import { useAuth } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Spinner } from "../organisms";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';

import { useShallow } from 'zustand/react/shallow';

import { usePrefsStore } from '../store';

const AuthRedditCallbackError = () => {
  return (
    <>
      <CardHeader>
        <CardTitle>Authorization failed</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="flex flex-col gap-4 justify-center items-center">
            <span>Authorization failed. Please try again.</span>
            <Button variant="accent" asChild className="w-fit">
              <Link to="/integrations-setup/new">
                Try again
              </Link>
            </Button>
          </div>
        </div>
      </CardContent>
    </>
  )
}

AuthRedditCallbackError.displayName = "AuthRedditCallbackError"

interface AuthRedditCallbackPendingProps {
  onStatusChange: (status: "error" | "success") => void;
  authorizationCode: string;
}

const AuthRedditCallbackPending: React.FC<AuthRedditCallbackPendingProps> = ({
  onStatusChange,
  authorizationCode
}) => {
  const { getToken } = useAuth();
  const hasMutated = useRef(false);
  const queryClient = useQueryClient();

  const createIntegration = useMutation({
    mutationFn: async (
      createIntegrationPayload: components["schemas"]["CreateIntegrationRequest"]
    ) => {
      const { data, error, response } = await fetcher.POST("/integrations", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: createIntegrationPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      onStatusChange("success")
      queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });
      toast.success(data.message)
    },
    onError: (error) => {
      onStatusChange("error")
      toast.error("Cannot create integration",
        { description: `${error}` })
    },
  })

  useEffect(() => {
    if (!hasMutated.current) {
      createIntegration.mutate({
        type: "reddit",
        authorization_code: authorizationCode
      })
      hasMutated.current = true;
    }
  }, []);

  return (
    <>
      <CardHeader>
        <CardTitle>Checking authorization</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="flex flex-col gap-4 justify-center items-center">
            <Spinner />
          </div>
        </div>
      </CardContent>
    </>
  )
}

AuthRedditCallbackPending.displayName = "AuthRedditCallbackPending"


const AuthRedditCallbackSuccess = () => {
  const navigate = useNavigate();
  const [resetRedditOAuthState] = usePrefsStore(
    useShallow((state) => [
      state.resetRedditOAuthState
    ]),
  )

  useEffect(() => {
    resetRedditOAuthState();
    window.setTimeout(() => {
      navigate("/integrations-setup");
    }, 3000)
  }, [])

  return (
    <>
      <CardHeader>
        <CardTitle>Authorization granted!</CardTitle>
      </CardHeader>
      <CardContent>
        You'll be redirected in a few seconds.
      </CardContent>
    </>
  )
}

AuthRedditCallbackSuccess.displayName = "AuthRedditCallbackSuccess"


const AuthRedditCallback = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnedOAuthState = urlParams.get("state");
  const returnedAuthorizationCode = urlParams.get("code")
  const [redditOAuthState] = usePrefsStore(
    useShallow((state) => [
      state.redditOAuthState,
    ]),
  )

  const [authStatus, setAuthStatus] = useState(
    (returnedOAuthState !== redditOAuthState) || (!returnedAuthorizationCode) ?
      "error" : "pending"
  );

  return (
    <div className="flex flex-col gap-4 w-full h-full items-center justify-center">
      <Card className="max-w-2xl text-center">
        {
          authStatus === "error" ? (
            <AuthRedditCallbackError />
          ) : (
            authStatus === "pending" ? (
              <AuthRedditCallbackPending
                onStatusChange={setAuthStatus}
                authorizationCode={returnedAuthorizationCode || ""}
              />
            ) : (
              <AuthRedditCallbackSuccess />
            )
          )
        }
      </Card>
    </div>
  )
}

export { AuthRedditCallback };
