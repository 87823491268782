import React, { useState } from "react";
import { PricingCard } from "../pricingCard/PricingCard";

import {
  Card,
  CardContent,
} from "@repo/ui/components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/tabs";
import { components } from '../../services/api/openapi';


interface PricingTableProps {
  pricingDefinitions: components["schemas"]["PricingDefinition"][]
}

const PricingTable: React.FC<PricingTableProps> = ({ pricingDefinitions }) => {
  return (
    <Tabs defaultValue="monthly" className="flex flex-col gap-8 w-full items-center">
      <TabsList className="grid w-60 grid-cols-2">
        <TabsTrigger value="monthly">Monthly</TabsTrigger>
        <TabsTrigger value="yearly">Yearly</TabsTrigger>
      </TabsList>
      <TabsContent value="monthly" className="w-full">
        <Card className="border-0 shadow-none">
          <CardContent className="flex w-full gap-4">
            {pricingDefinitions.map((def) => {
              return (
                <PricingCard
                  key={`monthly-${def.plan.name}`}
                  variant="monthly"
                  name={def.plan.name}
                  description={def.plan.description}
                  currency={def.billing.currency}
                  priceAmountMonthly={def.billing.monthly.price_amount}
                  priceAmountYearly={def.billing.yearly.price_amount}
                  priceLabelMonthly="/mo"
                  priceLabelYearly="/yr"
                  ctaText="Select plan"
                  ctaLink={def.billing.monthly.payment_link}
                  features={def.plan.features}
                  limits={def.plan.limits}
                />
              )
            })}
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="yearly" className="w-full">
        <Card className="border-0">
          <CardContent className="flex w-full gap-4">
            {pricingDefinitions.map((def) => {
              return (
                <PricingCard
                  key={`yearly-${def.plan.name}`}
                  variant="yearly"
                  name={def.plan.name}
                  description={def.plan.description}
                  currency={def.billing.currency}
                  priceAmountMonthly={def.billing.monthly.price_amount}
                  priceAmountYearly={def.billing.yearly.price_amount}
                  priceLabelMonthly="/mo"
                  priceLabelYearly="/yr"
                  ctaText="Select plan"
                  ctaLink={def.billing.yearly.payment_link}
                  features={def.plan.features}
                  limits={def.plan.limits}
                />
              )
            })}
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  )
}

PricingTable.displayName = "PricingTable";

export { PricingTable };
