import { Archive, Flame, FolderCog, FolderTree, Inbox, LucideProps, MailCheck, User } from "lucide-react";
import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { IntegrationsSetup } from "../routes/integrations-setup";
import { IntegrationsSetupNew } from "../routes/integrations-setup.new";
import { Account } from "../routes/profile.account";
import { Subscription } from "../routes/profile.subscription";
import { ProjectsCreate } from "../routes/projects.create";
import { ProjectsEdit } from "../routes/projects.edit.$id";
import { ProjectsList } from "../routes/projects.list";
import { Submissions } from "../routes/submissions";

const DummyComponent = () => {
  return (
    <div>Hello World!</div>
  )
}

enum RouteStatus {
  Live = "live",
  Beta = "beta",
  WIP = "wip",
  UpgradeRequired = "upgrade",
}

interface BaseNonIndexRouteConfig {
  label: string;
  path: string;
  ElementComponent: React.ComponentType;
  noMenu?: boolean;
  subItems?: BaseRouteConfig[];
  status?: RouteStatus;
}

interface BaseIndexRouteConfig {
  index: true;
  ElementComponent: React.ComponentType;
}

export type BaseRouteConfig = BaseNonIndexRouteConfig | BaseIndexRouteConfig;
export type FirstLevelNonIndexRouteConfig = {
  IconComponent: React.ComponentType<LucideProps>;
} & BaseNonIndexRouteConfig;
export type FirstLevelIndexRouteConfig = BaseIndexRouteConfig;
type FirstLevelRouteConfig = FirstLevelNonIndexRouteConfig | FirstLevelIndexRouteConfig;

interface RoutesSection {
  sectionName: string | null;
  path: string;
  ElementComponent?: React.ComponentType;
  items: FirstLevelRouteConfig[];
}

export type RoutesSectionsConfig = RoutesSection[];

const routesSections: RoutesSectionsConfig = [
  {
    sectionName: null,
    path: "",
    items: [
      {
        label: "Projects",
        path: "projects",
        IconComponent: FolderTree,
        subItems: [
          {
            index: true,
            noMenu: true,
            ElementComponent: ProjectsList,
          },
          {
            noMenu: true,
            label: "Create",
            path: "create",
            ElementComponent: ProjectsCreate,
          },
          {
            noMenu: true,
            label: "Edit",
            path: "edit/:projectId",
            ElementComponent: ProjectsEdit,
          },
        ]
      },
      {
        label: "Integrations Setup",
        path: "integrations-setup",
        IconComponent: FolderCog,
        subItems: [
          {
            index: true,
            noMenu: true,
            ElementComponent: IntegrationsSetup,
          },
          {
            noMenu: true,
            label: "New",
            path: "new",
            ElementComponent: IntegrationsSetupNew,
          },
        ]
      },
    ]
  },
  // {
  //   sectionName: "Overview",
  //   path: "overview",
  //   items: [
  //     {
  //       index: true,
  //       ElementComponent: RootIndex,
  //     },
  //     {
  //       label: "Dashboard",
  //       path: "overview/dashboard",
  //       ElementComponent: DummyComponent,
  //       IconComponent: ChartLine,
  //     },
  //   ]
  // },
  {
    sectionName: "Submissions",
    path: "submissions",
    ElementComponent: Submissions,
    items: [
      {
        label: "Inbox",
        path: "inbox",
        ElementComponent: DummyComponent,
        IconComponent: Inbox,
      },
      {
        label: "Hot",
        path: "hot",
        ElementComponent: DummyComponent,
        IconComponent: Flame,
      },
      {
        label: "Done",
        path: "done",
        ElementComponent: DummyComponent,
        IconComponent: MailCheck,
      },
      {
        label: "Archive",
        path: "archive",
        ElementComponent: DummyComponent,
        IconComponent: Archive,
      },
    ]
  },
  // {
  //   sectionName: "Content Marketing",
  //   path: "content-marketing",
  //   items: [
  //     {
  //       index: true,
  //       ElementComponent: RootIndex,
  //     },
  //     {
  //       label: "Dashboard",
  //       path: "content-marketing/dashboard",
  //       ElementComponent: DummyComponent,
  //       IconComponent: ChartLine,
  //     },
  //     {
  //       label: "Blog posts",
  //       path: "content-marketing/blog-posts",
  //       ElementComponent: DummyComponent,
  //       IconComponent: StickyNote,
  //     },
  //     {
  //       label: "Content repurpose",
  //       path: "content-marketing/content-repurpose",
  //       ElementComponent: DummyComponent,
  //       IconComponent: Recycle,
  //     },
  //   ]
  // },
  {
    sectionName: "User Management",
    path: "user-management",
    items: [
      {
        label: "Profile",
        path: "profile",
        ElementComponent: Outlet,
        IconComponent: User,
        subItems: [
          {
            label: "Account",
            path: "account",
            ElementComponent: Account,
          },
          {
            label: "Subscription",
            path: "subscription",
            ElementComponent: Subscription,
          }
        ]
      }
    ]
  },
]

const createRouteObjectForSubSection = (routeSubSection: BaseRouteConfig): RouteObject => {
  const ElementComponent = routeSubSection.ElementComponent;

  if ("index" in routeSubSection && routeSubSection.index) {
    return {
      index: true,
      element: <ElementComponent />,
    }
  } else {
    const firstLevelNonIndexRoute = routeSubSection as FirstLevelNonIndexRouteConfig;
    const children = (firstLevelNonIndexRoute.subItems || []).map(
      rdss => createRouteObjectForSubSection(rdss))
    const path = firstLevelNonIndexRoute.path;

    if (ElementComponent) {
      return {
        path,
        children,
        element: <ElementComponent />,
      }
    } else {
      return {
        path,
        children,
      }
    }
  }
}

const createRouteObjectForSection = (routeSection: RoutesSection) => {
  const path = routeSection.path;
  const children = routeSection.items.map(rss => createRouteObjectForSubSection(rss));
  const ElementComponent = routeSection.ElementComponent;

  if (ElementComponent) {
    return {
      path,
      children,
      element: <ElementComponent />,
    }
  } else {
    return {
      path,
      children,
    }
  }
}

const createRouteObjects = (routes: RoutesSectionsConfig) => {
  return routes.flatMap(r => {
    if (r.sectionName) {
      return createRouteObjectForSection(r)
    } else {
      return r.items.map(rss => createRouteObjectForSubSection(rss));
    }
  });
}

const routeObjects = createRouteObjects(routesSections)

export { routeObjects, routesSections, RouteStatus };
