import { useAuth, useUser } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import { Switch } from "@repo/ui/components/ui/switch";
import { useQuery } from '@tanstack/react-query';
import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import invariant from 'tiny-invariant';
import { InfoIcon, Spinner } from "../organisms/";
import { components } from '../services/api/openapi';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";
import { Input } from "@repo/ui/components/ui/input";
import { Textarea } from "@repo/ui/components/ui/textarea";

import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { fetcher, serializeError } from '../services/api';

const MAX_KEYWORDS = 50;

const formSchema = z.object({
  name: z.string().min(2).max(50),
  website: z.string().url(),
  description: z.string(),
  keywords: z.string().array().max(MAX_KEYWORDS)
    .transform((keywords) => keywords.filter(keyword => keyword.trim() !== '')),
  live: z.boolean(),
})

const apiSchema = z.object({
  name: z.string(),
  website: z.string(),
  description: z.string(),
  keywords: z.string().array(),
  status: z.enum(['live', 'draft']),
});

type FormType = z.infer<typeof formSchema>;
type APIType = z.infer<typeof apiSchema>;

const apiToFormSchema = apiSchema.transform((data) => {
  const transformedData = { ...data } as Partial<APIType>
  delete transformedData.status
  return {
    ...transformedData,
    live: data.status === 'live',
  }
})

const formToApiSchema = formSchema.transform((data) => {
  const transformedData = { ...data } as Partial<FormType>
  delete transformedData.live
  return {
    ...transformedData,
    status: data.live ? "live" : "draft",
  }
})


interface ProjectFormProps {
  projectId: number;
  defaultValues: FormType;
}

const ProjectForm: React.FC<ProjectFormProps> = ({ projectId, defaultValues }) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'keywords',
  });

  const projectsUpdate = useMutation({
    mutationFn: async (projectPayload: components["schemas"]["UpdateProjectRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/projects/{{project_id}}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            project_id: projectId
          }
        },
        body: projectPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['get', 'projects'] });
      queryClient.invalidateQueries({ queryKey: ['get', 'projects', projectId] });
      queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });

      toast.success("Project updated!",
        { description: data.data.name })
      return navigate("/projects");
    },
    onError: (error) => {
      toast.error("Cannot update project",
        { description: `${error}` })
    },
  })

  const onSubmit = (values: FormType) => {
    projectsUpdate.mutate(formToApiSchema.parse(values))
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-6">

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-2 items-center">
                <FormLabel>Name</FormLabel>
                <InfoIcon
                  className="size-4"
                  text="The name is used when generating the replies to mention your project."
                />
              </div>

              <FormControl>
                <Input placeholder="My Awesome Project" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Website</FormLabel>
              <FormControl>
                <Input placeholder="https://myawesomewebsite.com" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-2 items-center">
                <FormLabel>Description</FormLabel>
                <InfoIcon
                  className="size-4"
                  text="The description is used to remove the false positives that match the keywords. Leaving this blank or providing a poor description will decrease the matching quality."
                />
              </div>

              <FormControl>
                <Textarea
                  placeholder="Tell us a little bit about your project"
                  className="resize-none h-80"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-2">
          <div className="flex gap-2 items-center">
            <FormLabel>Keywords</FormLabel>
            <InfoIcon
              className="size-4"
              text="The keywords are used in the initial step to find relevant submissions. A fuzzy match is applied to ensure that misspellings are not excluded."
            />
          </div>

          <div className="flex gap-2">
            <div className="flex flex-wrap gap-2">
              {fields.map((field, index) => (
                <FormItem key={field.id}>
                  <div className="flex gap-1 border w-fit p-1 rounded-md">
                    <FormControl>
                      <Input
                        placeholder="Keyword"
                        className="w-fit border-0 bg-transparent py-0 h-6"
                        {...form.register(`keywords.${index}` as const)}
                      />
                    </FormControl>
                    <Button
                      type="button"
                      variant="secondary"
                      size="small-icon"
                      aria-label="Delete keyword"
                      onClick={() => remove(index)}
                    >
                      <X className="size-5" />
                    </Button>
                  </div>
                </FormItem>
              ))}
            </div>
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={() => append('')}
              disabled={fields.length >= MAX_KEYWORDS}
            >
              Add keyword
            </Button>
          </div>
        </div>

        <FormField
          control={form.control}
          name="live"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="flex gap-2 items-center">
                <FormLabel className="text-base flex gap-2 justify-center items-center">
                  <span>Live</span><span className="text-xs italic font-regular text-muted-foreground">(the first discovery will take up to 10 minutes)</span>
                </FormLabel>
                <InfoIcon
                  className="size-4"
                  text="Make the project live if you want to start finding relevant conversations."
                />
              </div>

              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className="w-fit self-end"
        >
          Save
        </Button>
      </form>
    </Form >
  )
}

ProjectForm.displayName = "ProjectForm"


const ProjectsEdit = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const { projectId } = useParams();
  invariant(projectId, "Unexpected projectId")

  const { data, error, status } = useQuery({
    queryKey: ["get", "projects", projectId],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/projects/{{project_id}}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            project_id: parseInt(projectId)
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="flex flex-col gap-8">

      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold">Edit project</h1>
      </div>

      <div className="flex flex-col gap-4">
        <Card
          className="min-w-80 w-full"
        >
          <CardHeader>
            <div className="flex justify-between items-baseline">
              <CardTitle>Details</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <ProjectForm
              projectId={parseInt(projectId)}
              defaultValues={apiToFormSchema.parse({
                name: data.data.name,
                description: data.data.description,
                website: data.data.website,
                keywords: data.data.keywords,
                status: data.data.status
              })}
            />
          </CardContent>
        </Card>
      </div>

    </div>
  )
}

export { ProjectsEdit };
