import { useAuth, useUser } from '@clerk/clerk-react';
import { faSquareReddit } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/select";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Plus, X } from "lucide-react";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { Spinner } from "../organisms";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';


interface ProjectMappingIntegrationSelectProps {
  project: components["schemas"]["FullProject"]
  integrations: components["schemas"]["FullIntegration"][]
  projectIntegration?: components["schemas"]["FullProjectIntegration"] | null
}

const ProjectMappingIntegrationSelect: React.FC<ProjectMappingIntegrationSelectProps> = ({
  project,
  integrations,
  projectIntegration,
}) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const [key, setKey] = useState(+new Date())
  const [currentValue, setCurrentValue] = useState(projectIntegration?.integration_id || undefined)

  const updateProjectIntegration = useMutation({
    mutationFn: async ({
      projectIntegrationId,
      updateProjectIntegrationPayload
    }: {
      projectIntegrationId: number,
      updateProjectIntegrationPayload: components["schemas"]["UpdateProjectIntegrationRequest"]
    }) => {
      const { data, error, response } = await fetcher.PATCH("/project-integrations/{project_integration_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            project_integration_id: projectIntegrationId
          }
        },
        body: updateProjectIntegrationPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "projects", { expand: "projects_integrations" }] });
      toast.success(data.message)
    },
    onError: (error) => {
      toast.error("Cannot update project integration",
        { description: `${error}` })
    },
  })

  const createProjectIntegration = useMutation({
    mutationFn: async (createProjectIntegrationPayload: components["schemas"]["CreateProjectIntegrationRequest"]) => {
      const { data, error, response } = await fetcher.POST("/project-integrations", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: createProjectIntegrationPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "projects", { expand: "projects_integrations" }] });
      toast.success(data.message)
    },
    onError: (error) => {
      toast.error("Cannot create project integration",
        { description: `${error}` })
    },
  })

  const deleteProjectIntegration = useMutation({
    mutationFn: async (projectIntegrationId: number) => {
      const { data, error, response } = await fetcher.DELETE("/project-integrations/{project_integration_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            project_integration_id: projectIntegrationId
          }
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "projects", { expand: "projects_integrations" }] });
      toast.success(data.message)
    },
    onError: (error) => {
      toast.error("Cannot delete project integration",
        { description: `${error}` })
    },
  })

  return (
    <>
      <Select
        key={key}
        value={currentValue}
        onValueChange={
          (integrationId: number) => {
            if (projectIntegration) {
              updateProjectIntegration.mutate({
                projectIntegrationId: projectIntegration.id,
                updateProjectIntegrationPayload: {
                  project_id: project.id,
                  integration_id: integrationId
                }
              })
            } else {
              createProjectIntegration.mutate({
                project_id: project.id,
                integration_id: integrationId
              })
            }
          }
        }>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Select integration" />
        </SelectTrigger>
        <SelectContent>
          {integrations.map(integration => (
            <SelectItem key={`integration-${integration.id}`} value={integration.id}>{integration.username}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Button
        className="group hover:bg-error"
        variant="ghost"
        size="icon"
        disabled={!projectIntegration?.id}
        aria-label="Delete"
        onClick={() => {
          setCurrentValue(undefined);
          setKey(+new Date());
          if (projectIntegration) {
            deleteProjectIntegration.mutate(projectIntegration.id);
          }
        }}
      >
        <X className="size-5 group-hover:size-6 group-hover:text-error-foreground transition-all" />
      </Button>
    </>
  )
}


interface ProjectMappingCardContentProps {}

const ProjectMappingCardContent: React.FC<ProjectMappingCardContentProps> = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const {
    data: projectsData,
    error: projectsError,
    status: projectsStatus
  } = useQuery({
    queryKey: ["get", "projects", { expand: "projects_integrations" }],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/projects", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: ["projects_integrations"],
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  const {
    data: integrationsData,
    error: integrationsError,
    status: integrationsStatus
  } = useQuery({
    queryKey: ["get", "integrations"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/integrations", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (projectsStatus === "pending" || integrationsStatus === "pending") {
    return (
      <Spinner />
    )
  }

  if (projectsStatus === "error" || integrationsStatus === "error") {
    return <span>Error: {(projectsError || integrationsError)?.message}</span>
  }

  return (
    <div className="flex flex-col gap-2 max-w-2xl">
      {
        projectsData.data.map(p => {
          const projectIntegrations = p.projects_integrations?.filter(pi => !pi.is_deleted);
          const projectIntegration = (
            projectIntegrations && projectIntegrations.length > 0
          ) ? projectIntegrations[0] : null;

          return (
            <div
              key={`project-${p.id}`}
              className="flex justify-between items-center w-full"
            >
              <div className="flex gap-2 items-baseline">
                <span>{p.name}</span>
                <span className="italic text-xs text-muted-foreground">({p.website})</span>
              </div>

              <div className="flex justify-end items-center w-full gap-1">
                <ProjectMappingIntegrationSelect
                  project={p}
                  integrations={integrationsData.data}
                  projectIntegration={projectIntegration}
                />
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

ProjectMappingCardContent.displayName = "ProjectMappingCardContent"


interface ProjectMappingCardProps {
}


const ProjectMappingCard: React.FC<ProjectMappingCardProps> = () => {
  return (
    <Card
      className="min-w-80 w-full"
    >
      <CardHeader>
        <CardTitle>Project Mapping</CardTitle>
        <CardDescription>Connect projects to integrations</CardDescription>
      </CardHeader>
      <CardContent>
        <ProjectMappingCardContent />
      </CardContent>
    </Card>
  )
}


ProjectMappingCard.displayName = "ProjectMappingCard";


interface IntegrationsOverviewCardContentProps {}


const IntegrationsOverviewCardContent: React.FC<IntegrationsOverviewCardContentProps> = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const queryClient = useQueryClient();

  const {
    data: integrationsData,
    error: integrationsError,
    status: integrationsStatus
  } = useQuery({
    queryKey: ["get", "integrations"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/integrations", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  const deleteIntegration = useMutation({
    mutationFn: async (integrationId: number) => {
      const { data, error, response } = await fetcher.DELETE("/integrations/{integration_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            integration_id: integrationId
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "integrations"] });
      queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });

      toast.success(data.message)
    },
    onError: (error) => {
      toast.error("Cannot create project integration",
        { description: `${error}` })
    },
  })


  if (integrationsStatus === "pending") {
    return (
      <Spinner />
    )
  }

  if (integrationsStatus === "error") {
    return <span>Error: {integrationsError.message}</span>
  }

  return (
    <div className="flex flex-col gap-2 max-w-lg">
      {integrationsData.data.map((integration) => (
        <div
          key={`integration-${integration.id}`}
          className="flex justify-between items-center w-full"
        >
          <div className="flex gap-4 items-center">
            <FontAwesomeIcon className="text-[#FF7500]" size="2xl" icon={faSquareReddit} />
            <span>{integration.username}</span>
          </div>
          <Button
            className="group hover:bg-error"
            variant="ghost"
            size="icon"
            aria-label="Delete"
            onClick={() => deleteIntegration.mutate(integration.id)}
          >
            <X className="size-5 group-hover:size-6 group-hover:text-error-foreground transition-all" />
          </Button>
        </div>
      )
      )}
    </div>
  )
}


IntegrationsOverviewCardContent.displayName = "IntegrationsOverviewCardContent";


interface IntegrationsOverviewCardProps {}


const IntegrationsOverviewCard: React.FC<IntegrationsOverviewCardProps> = () => {

  return (
    <Card
      className="min-w-80 w-full"
    >
      <CardHeader>
        <CardTitle>
          <div className="flex justify-between">
            <h1>Integrations Overview</h1>
            <Button
              size="sm"
              aria-label="Create project"
              asChild
            >
              <Link to="/integrations-setup/new">
                <div className="flex items-center gap-2">
                  <Plus strokeWidth={2} className="size-5" />
                  <span>New</span>
                </div>
              </Link>
            </Button>
          </div>
        </CardTitle>
        <CardDescription>Overview of all integrations setup</CardDescription>
      </CardHeader>
      <CardContent>
        <IntegrationsOverviewCardContent />
      </CardContent>
    </Card>
  )
}


IntegrationsOverviewCard.displayName = "IntegrationsOverviewCard";



interface IntegrationsSetupProps {
}


const IntegrationsSetup: React.FC<IntegrationsSetupProps> = () => {

  return (
    <div className="flex flex-col gap-8">
      <h1 className="text-3xl font-semibold">Integrations Setup</h1>

      <div className="flex flex-col gap-4">
        <ProjectMappingCard />
        <IntegrationsOverviewCard />
      </div>
    </div>
  )
}


IntegrationsSetup.displayName = "IntegrationsSetup";


export { IntegrationsSetup };
