import { useAuth, useUser } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@repo/ui/components/ui/resizable";
import { ScrollArea } from "@repo/ui/components/ui/scroll-area";
import { Textarea } from "@repo/ui/components/ui/textarea";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Archive, Check, LoaderCircle, Maximize2, Minimize2, Send, WandSparkles, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from "sonner";
import { default as invariant } from 'tiny-invariant';
import { PaginationWidget, RedditSubmissionCandidateCard, RedditSubmissionCandidateCardPreview, Spinner } from "../organisms/";
import { plausible } from '../services/analytics';
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';
import { usePrefsStore } from '../store';


const Submissions = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [selectedSubmissionCandidateId, setSelectedSubmissionCandidateId] = useState<number | null>(null)
  const isExpanded = searchParams.get("expand") === "true";

  useEffect(() => {
    setSelectedSubmissionCandidateId(null);
  }, [location.pathname])

  useEffect(() => {
    const rscId = searchParams.get("submission-id");
    if (!rscId) {
      if (selectedSubmissionCandidateId) {
        setSelectedSubmissionCandidateId(null)
      }
    } else {
      if (selectedSubmissionCandidateId !== parseInt(rscId)) {
        setSelectedSubmissionCandidateId(parseInt(rscId));
      }
    }
  }, [searchParams])

  return (
    <div className="flex flex-col gap-1 h-[calc(100vh-112px)]">
      <h1 className="text-3xl font-semibold">Submissions</h1>

      <div className="flex h-full">
        {
          !isExpanded && selectedSubmissionCandidateId ? (
            <SubmissionsResizableContent
              selectedSubmissionCandidateId={selectedSubmissionCandidateId}
            />
          ) : (
            <SubmissionsFixedContent
              selectedSubmissionCandidateId={selectedSubmissionCandidateId}
              isExpanded={isExpanded}
            />
          )
        }

      </div>
    </div>
  )
}

const SubmissionsResizableContent = ({ selectedSubmissionCandidateId }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSubmissionCandidateIdSearchParam = (rscId: number) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("submission-id", rscId.toString());
    setSearchParams(updatedParams)
  }

  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="w-full"
    >
      <ResizablePanel defaultSize={40} minSize={40} >
        <SubmissionsList
          onCardClick={(rsc) => {
            setSubmissionCandidateIdSearchParam(rsc.id)
            plausible.track("Drill down submission", {
              submission_id: rsc.submission_id,
              submission_candidate_id: rsc.id,
              project_id: rsc.submission?.discovery?.project_id || "",
            })
          }}
        />
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel defaultSize={60} minSize={50} className="border-t border-b border-r">
        <SubmissionHandling submissionCandidateId={selectedSubmissionCandidateId} />
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}

const SubmissionsFixedContent = ({ selectedSubmissionCandidateId, isExpanded }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSubmissionCandidateIdSearchParam = (rscId: number) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("submission-id", rscId.toString());
    setSearchParams(updatedParams)
  }

  return (
    <>
      {
        isExpanded ? (
          <SubmissionHandling submissionCandidateId={selectedSubmissionCandidateId} />
        ) : (
          <SubmissionsList
            onCardClick={(rsc) => {
              setSubmissionCandidateIdSearchParam(rsc.id)
              plausible.track("Drill down submission", {
                submission_id: rsc.submission_id,
                submission_candidate_id: rsc.id,
                project_id: rsc.submission?.discovery?.project_id || "",
              })
            }}
          />
        )
      }
    </>
  )
}

interface SubmissionsListProps {
  onCardClick: (rsc: components["schemas"]["FullRedditSubmissionCandidate"]) => void
}

const SubmissionsList: React.FC<SubmissionsListProps> = ({
  onCardClick
}) => {
  const location = useLocation();
  let subSection, submissionStatus, subSectionDesc;
  if (location.pathname === '/submissions/inbox') {
    subSection = "inbox";
    submissionStatus = "new";
    subSectionDesc = "All the new submissions to engage with";
  } else if (location.pathname === '/submissions/hot') {
    subSection = "hot";
    submissionStatus = "hot";
    subSectionDesc = "The most recently posted submissions to engage with";
  } else if (location.pathname === '/submissions/done') {
    subSection = "done";
    submissionStatus = "done";
    subSectionDesc = "All the submissions you engaged with";
  } else if (location.pathname === '/submissions/archive') {
    subSection = "archive";
    submissionStatus = "archived";
    subSectionDesc = "All the submissions you discarded";
  } else {
    throw new Error("Unexpected section");
  }

  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const currentProject = usePrefsStore((state) => state.currentProject)
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get('page') || '1';
  const rawPage = parseInt(pageParam, 10);
  const page = isNaN(rawPage) ? 1 : rawPage;

  const { data, error, status } = useQuery({
    queryKey: [
      "get", "reddit", "submission-candidates",
      currentProject.id, submissionStatus, page
    ],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/reddit/submission-candidates", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            project_id: currentProject.id,
            expand: ["submission", "submission.discovery", "submission.stats", "submission.owned_reply"],
            page_number: page,
            page_size: 10,
            status: submissionStatus,
            order_by: "score",
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <Card className="w-full h-full border-none shadow-none flex flex-col">
      <CardHeader className="px-0 py-4 flex-grow">
        <CardTitle className="text-left capitalize">{subSection}</CardTitle>
        <CardDescription>{subSectionDesc}</CardDescription>
      </CardHeader>
      <CardContent className="max-h-full flex-shrink flex flex-col p-0 overflow-hidden">
        <ScrollArea className="h-full">
          <div className="flex flex-col gap-4 pr-8">
            {
              data.data.map((rsc, i) => (
                <RedditSubmissionCandidateCardPreview
                  key={`reddit-card-${i}`}
                  submissionCandidate={rsc}
                  onClick={() => onCardClick(rsc)}
                  isSelected={rsc.id.toString() === searchParams.get("submission-id")}
                />
              ))
            }
          </div>
        </ScrollArea>
      </CardContent>
      <CardFooter className="px-0 py-6 flex-grow">
        {
          data.meta.total_pages > 0 && (
            <PaginationWidget pageQueryParam="page" currentPage={page} maxPage={data.meta.total_pages} />
          )
        }
      </CardFooter>
    </Card>
  )
}

interface SubmissionHandlingProps {
  submissionCandidateId: number | null
}

const SubmissionHandling: React.FC<SubmissionHandlingProps> = ({
  submissionCandidateId
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isExpanded = searchParams.get("expand") === "true";

  const resetSubmissionsSearchParams = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("submission-id");
    updatedParams.delete("expand");
    setSearchParams(updatedParams)
  }

  const setExpandSearchParam = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set("expand", "true");
    setSearchParams(updatedParams)
  }

  const resetExpandSearchParam = () => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete("expand");
    setSearchParams(updatedParams)
  }

  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const updateStatus = useMutation({
    mutationFn: async (updateStatusPayload: components["schemas"]["UpdateRedditSubmissionCandidateRequest"]) => {
      if (!submissionCandidateId) {
        return
      }

      const { data, error, response } = await fetcher.PATCH("/reddit/submission-candidates/{reddit_submission_candidate_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            reddit_submission_candidate_id: submissionCandidateId
          }
        },
        body: updateStatusPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      if (!submissionCandidateId) {
        return
      }

      queryClient.invalidateQueries({ queryKey: ["get", "reddit", "submission-candidates"] });
      toast.success("Status changed!")
    },
    onError: () => {
      if (!submissionCandidateId) {
        return
      }

      toast.error("Cannot change status")
    },
  })

  const { data, error, status } = useQuery({
    queryKey: [
      "get", "reddit", "submission-candidates", submissionCandidateId
    ],
    queryFn: async () => {
      invariant(submissionCandidateId, "Unexpected missing Reddit submission candidate id")
      const { data, error, response } = await fetcher.GET("/reddit/submission-candidates/{reddit_submission_candidate_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            reddit_submission_candidate_id: submissionCandidateId,
          },
          query: {
            expand: ["submission", "submission.discovery", "submission.stats", "submission.owned_reply"],
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: !!submissionCandidateId
  })

  if (status === "pending" && !!submissionCandidateId) {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  const submissionCandidate = data?.data;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between w-full border-b gap-1 px-2 py-1">
        <div className="flex gap-1 w-full">
          <Button
            className="group hover:bg-error"
            variant="ghost"
            size="icon"
            disabled={!submissionCandidate}
            aria-label="Close"
            onClick={resetSubmissionsSearchParams}
          >
            <X className="size-5 group-hover:size-6 group-hover:text-error-foreground transition-all" />
          </Button>
          <Button
            className="group hover:bg-warning"
            variant="ghost"
            size="icon"
            disabled={!submissionCandidate}
            aria-label="Maximize/Minimize"
            onClick={isExpanded ? resetExpandSearchParam : setExpandSearchParam}
          >
            {
              isExpanded ?
                <Minimize2 className="size-5 group-hover:size-6 group-hover:text-warning-foreground transition-all" /> :
                <Maximize2 className="size-5 group-hover:size-6 group-hover:text-warning-foreground transition-all" />
            }
          </Button>
        </div>
        <div className="flex justify-end gap-1 w-full">
          <Button
            className="group hover:bg-success"
            variant="ghost"
            size="icon"
            disabled={!submissionCandidate}
            aria-label="Mark as done"
            onClick={() => {
              invariant(submissionCandidate, "Unexpected missing submission candidate")
              updateStatus.mutate({ status: "done" })
              plausible.track("Done submission", {
                submission_id: submissionCandidate.submission_id,
                submission_candidate_id: submissionCandidate.id,
                project_id: submissionCandidate.submission?.discovery?.project_id || "",
              })
              resetSubmissionsSearchParams()
            }}
          >
            <Check className="size-5 group-hover:size-6 group-hover:text-success-foreground transition-all" />
          </Button>
          <Button
            className="group hover:bg-warning"
            variant="ghost"
            size="icon"
            disabled={!submissionCandidate}
            aria-label="Archive"
            onClick={() => {
              invariant(submissionCandidate, "Unexpected missing submission candidate")
              updateStatus.mutate({ status: "archived" })
              plausible.track("Archive submission", {
                submission_id: submissionCandidate.submission_id,
                submission_candidate_id: submissionCandidate.id,
                project_id: submissionCandidate.submission?.discovery?.project_id || "",
              })
              resetSubmissionsSearchParams()
            }}
          >
            <Archive className="size-5 group-hover:size-6 group-hover:text-warning-foreground transition-all" />
          </Button>
        </div>
      </div>
      {submissionCandidate ? (
        <div className="flex flex-col w-full h-full relative overflow-y-scroll">
          <div className={`p-0 w-full ${isExpanded ? "border-l" : ""}`}>
            <RedditSubmissionCandidateCard
              submissionCandidate={submissionCandidate}
            />
          </div>
          <div className={`border-t ${isExpanded ? "border-l" : ""} w-full sticky bottom-0`}>
            <SubmissionHandlingReplyBox submissionCandidate={submissionCandidate} />
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full w-full justify-center items-center">
          <span>Select a submission</span>
        </div>
      )}
    </div>
  )
}

interface SubmissionHandlingReplyBoxProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
}

const SubmissionHandlingReplyBox: React.FC<SubmissionHandlingReplyBoxProps> = ({
  submissionCandidate
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing submission");
  invariant(submissionCandidate.submission.stats, "Unexpected missing submission's stats");

  const [replyText, setReplyText] = useState(
    submissionCandidate.submission.owned_reply ?
      submissionCandidate.submission.owned_reply.text : ""
  );
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (submissionCandidate.submission?.owned_reply) {
      setReplyText(submissionCandidate.submission.owned_reply.text)
    } else {
      setReplyText("")
    }
  }, [submissionCandidate.submission.id])

  const createOwnedReply = useMutation({
    mutationFn: async (createOwnedReplyPayload: components["schemas"]["CreateRedditOwnedReplyCandidateRequest"]) => {
      const { data, error, response } = await fetcher.POST("/reddit/owned-reply-candidates", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: createOwnedReplyPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      const ownedReplyId = data.data.id;
      updateOwnedReply.mutate({
        ownedReplyId,
        updateOwnedReplyPayload: { text: replyText }
      })

      queryClient.invalidateQueries({ queryKey: ["get", "reddit", "submission-candidates", submissionCandidate.id] });
    },
    onError: () => {
      toast.error("Cannot create reply")
    },
  })

  const updateOwnedReply = useMutation({
    mutationFn: async ({
      ownedReplyId,
      updateOwnedReplyPayload
    }: {
      ownedReplyId: number,
      updateOwnedReplyPayload: components["schemas"]["UpdateRedditOwnedReplyCandidateRequest"]
    }) => {
      const { data, error, response } = await fetcher.PATCH("/reddit/owned-reply-candidates/{reddit_owned_reply_candidate_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            reddit_owned_reply_candidate_id: ownedReplyId
          }
        },
        body: updateOwnedReplyPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "reddit", "submission-candidates", submissionCandidate.id] });
    },
    onError: () => {
      toast.error("Cannot update reply")
    },
  })

  const generateOwnedReply = useMutation({
    mutationFn: async (
      generateOwnedReplyPayload: components["schemas"]["GenerateRedditOwnedReplyCandidateRequest"]
    ) => {
      const { data, error, response } = await fetcher.POST("/reddit/owned-reply-candidates/generate", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: generateOwnedReplyPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      setReplyText(data.data.reply);
      if (!submissionCandidate.submission?.owned_reply) {
        const parentSubmissionId = submissionCandidate.submission?.id;
        invariant(parentSubmissionId, "Unexpected missing submission id")

        createOwnedReply.mutate({
          parent_submission_id: parentSubmissionId
        })
      } else {
        const ownedReplyId = submissionCandidate.submission?.owned_reply.id

        updateOwnedReply.mutate({
          ownedReplyId,
          updateOwnedReplyPayload: { text: data.data.reply }
        })
      }

      toast.success("The reply has been generated!")
    },
    onError: () => {
      toast.error("Cannot generate reply")
    },
  })

  const sendOwnedReply = useMutation({
    mutationFn: async (
      sendOwnedReplyPayload: components["schemas"]["SendRedditOwnedReplyCandidateRequest"]
    ) => {
      const { data, error, response } = await fetcher.POST("/reddit/owned-reply-candidates/send", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: sendOwnedReplyPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      toast.success("The reply has been sent!")
    },
    onError: () => {
      toast.error("Cannot send reply. Did you have integrations setup?")
    },
  })

  const onTextAreaChange = (ev) => {
    const newValue = ev.target.value;
    setReplyText(newValue);
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (!submissionCandidate.submission?.owned_reply) {
        const parentSubmissionId = submissionCandidate.submission?.id;
        invariant(parentSubmissionId, "Unexpected missing submission id")

        createOwnedReply.mutate({
          parent_submission_id: parentSubmissionId
        })
      } else {
        const ownedReplyId = submissionCandidate.submission?.owned_reply.id

        updateOwnedReply.mutate({
          ownedReplyId,
          updateOwnedReplyPayload: { text: newValue }
        })
      }

      timeoutRef.current = null;
    }, 2000);
  }

  const hasReplied = !!submissionCandidate.submission?.owned_reply?.external_id

  return (
    <div className="flex flex-col p-6 gap-4 bg-background">
      <Textarea
        placeholder="Write or generate reply"
        className="resize-none h-28"
        value={replyText}
        onChange={onTextAreaChange}
        readOnly={hasReplied}
        disabled={hasReplied}
      />

      <div className="flex gap-2 self-end">
        <Button
          aria-label="Generate reply"
          className="flex gap-2"
          disabled={generateOwnedReply.status === "pending" || hasReplied}
          onClick={() => {
            setReplyText("")
            generateOwnedReply.mutate({ parent_submission_id: submissionCandidate.submission_id })
            plausible.track("Generate reply", {
              submission_id: submissionCandidate.submission_id,
              submission_candidate_id: submissionCandidate.id,
              project_id: submissionCandidate.submission?.discovery?.project_id || "",
            })
          }}
        >
          {
            generateOwnedReply.status === "pending" ? (
              <LoaderCircle className="size-5 animate-spin" />
            ) : (
              <WandSparkles className="size-5" />
            )
          }
          <span>Generate</span>
        </Button>
        <Button
          variant="accent"
          aria-label="Reply"
          className="flex gap-2"
          disabled={!submissionCandidate.submission?.owned_reply || sendOwnedReply.status === "pending" || hasReplied}
          onClick={() => {
            invariant(submissionCandidate.submission?.owned_reply?.id, "Unexpected missing Reddit owner reply candidate id")
            sendOwnedReply.mutate({
              reddit_owned_reply_candidate_id: submissionCandidate.submission.owned_reply.id
            })
          }}
        >
          {
            sendOwnedReply.status === "pending" ? (
              <LoaderCircle className="size-5 animate-spin" />
            ) : (
              <Send className="size-5" />
            )
          }
          <span>Send</span>
        </Button>
      </div>
    </div>
  )
}

export { Submissions };
