import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@repo/ui/components/ui/chart";
import { ExternalLink, Flame, Gauge, MousePointerClick, Scan } from "lucide-react";
import invariant from 'tiny-invariant';
import { components } from '../../services/api/openapi';

const timeAgo = (timestamp: string): string => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = Math.floor(hours / 24);
  if (days < 30) {
    return `${days}d`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months}mo`;
  }

  const years = Math.max(1, Math.floor(days / 365));
  return `${years}y`;
}

const isRecent = (timestamp: string): boolean => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);
  const hours = Math.floor(seconds / 3660);
  return hours <= 48;
}

const getScoreVariantColor = (score: number, asVar: boolean = false): string => {
  let badgeVariant = "score-green";
  if (score < 50) {
    badgeVariant = "score-red";
  } else if (score < 60) {
    badgeVariant = "score-orange";
  } else if (score < 70) {
    badgeVariant = "score-yellow";
  } else if (score < 80) {
    badgeVariant = "score-yellowgreen";
  } else if (score < 90) {
    badgeVariant = "score-lightgreen";
  }

  if (asVar) {
    return `var(--${badgeVariant})`
  } else {
    return badgeVariant
  }
}

interface RedditSubmissionCandidateCardPreviewProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
  onClick: () => void,
  isSelected: boolean,
}

const RedditSubmissionCandidateCardPreview: React.FC<RedditSubmissionCandidateCardPreviewProps> = ({
  submissionCandidate,
  onClick,
  // TODO: use variants
  isSelected,
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing Reddit submission")
  invariant(submissionCandidate.submission.discovery, "Unexpected missing Reddit submission.discovery")
  invariant(submissionCandidate.submission.stats, "Unexpected missing Reddit submission.stats")

  const relevancy = submissionCandidate.score_breakdown?.relevancy?.reason || "";
  return (
    <div className="flex flex-col gap-2">
      <Card className={`min-w-80 w-full cursor-pointer ${isSelected ? "bg-muted border-primary/50" : ""} hover:bg-muted transition-all`} onClick={onClick}>
        <CardHeader className="p-4 pb-2">
          <RedditSubmissionCardHeader submissionCandidate={submissionCandidate} />
        </CardHeader>
        <CardContent className="p-4 pt-0">

          <div className="flex flex-col gap-1 w-full">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <div className="flex gap-2 h-5 text-xs items-center">
                  <span className="text-xs">r/{submissionCandidate.submission.sub}</span>
                  ·
                  <span className="flex gap-2 items-center">
                    <span className="text-xs">{timeAgo(submissionCandidate.submission.submitted_at)} ago</span>
                  </span>
                  {
                    isRecent(submissionCandidate.submission.submitted_at) && (
                      <div
                        className="flex h-5 justify-center items-center bg-red-600/30 rounded-md"
                      >
                        <div className="w-5 h-5 flex justify-center items-center animate-ping">
                          <Flame strokeWidth={3} className="size-3 text-red-600 fill-orange-300" />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <CardTitle className="text-sm">{submissionCandidate.submission.title}</CardTitle>
          </div>

        </CardContent>
        {relevancy && (
          <CardFooter className="p-4 pt-0">
            <div className="flex flex-col gap-1 w-full text-muted-foreground">
              <Badge
                variant="outline"
                className="text-sm w-fit h-fit text-muted-foreground"
              >
                <div className="flex gap-1 text-sm">
                  <MousePointerClick className="size-5" />
                  <span>Relevancy</span>
                </div>
              </Badge>
              <p className="italic text-sm">{relevancy}</p>
            </div>
          </CardFooter>
        )}
      </Card>

    </div>
  )
}

interface RedditSubmissionCardHeaderProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
}

const RedditSubmissionCardHeader: React.FC<RedditSubmissionCardHeaderProps> = ({
  submissionCandidate
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing Reddit submission")
  invariant(submissionCandidate.submission.discovery, "Unexpected missing Reddit submission.discovery")
  invariant(submissionCandidate.submission.stats, "Unexpected missing Reddit submission.stats")

  const score = submissionCandidate.score;

  const badgeVariant = getScoreVariantColor(score);

  return (
    <>
      <div className="flex flex-col gap-4 justify-between items-center">
        <div className="flex justify-between w-full">
          <div className="flex gap-1">
            <div
              className="flex h-8 justify-center items-center bg-muted rounded-md pr-2"
            >
              <div className="w-10 h-6 flex justify-center items-center">
                <Scan className="size-5" />
              </div>
              <span className="text-xs">Discovered {timeAgo(submissionCandidate.submission.discovery?.finished_at || "")} ago</span>
            </div>
            <Badge
              variant={badgeVariant}
              className="text-md h-8"
            >
              <div className="flex gap-2 items-center text-sm">
                <Gauge className="size-5" /> {score}
              </div>
            </Badge>
          </div>

          <div className="flex gap-1">
            <Button
              className="group h-8 w-8"
              variant="secondary"
              size="icon"
              aria-label="Go to external link"
              asChild
            >
              <a
                href={submissionCandidate.submission.link}
                target="_blank"
                onClick={(ev) => ev.stopPropagation()}
              >
                <ExternalLink className="size-5 group-hover:size-6 transition-all" />
              </a>
            </Button>
          </div>

        </div>

      </div>
    </>
  )
}

RedditSubmissionCardHeader.displayName = "RedditSubmissionCardHeader";

export { RedditSubmissionCandidateCardPreview };
